'use strict';

var atrack;

const initAnalyticsTracker = () => {
// Initialize the GDDL analytics tracker
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
};

const initStep1 = () => {
    $('body').on('shown.bs.modal', '#requestPasswordResetModal', function () {
        atrack.trackEvent({
            event: 'tool-step',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 1,
                step_name: 'enter email address',
                result: '',
                error_type: '',
                error_message: ''
            }
        });
    });
};

const initStep2 = () => {
    $('.reset-password-form').on('passwordreset:mail-sent', function () {
        atrack.trackEvent({
            event: 'tool-step',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 2,
                step_name: 'email sent',
                result: '',
                error_type: '',
                error_message: ''
            }
        });
    });
};

const initStep2Error = () => {
    $('.reset-password-form').on('passwordreset:error', function () {
        atrack.trackEvent({
            event: 'tool-error',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 2,
                step_name: 'email sent',
                result: '',
                error_type: 'system',
                error_message: 'Onbekende error'
            }
        });
    });
};

const initStep3 = () => {
    if ($('.page').data('action') === 'Account-SetNewPassword') {
        atrack.trackEvent({
            event: 'tool-step',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 3,
                step_name: 'set new password',
                result: '',
                error_type: '',
                error_message: ''
            }
        });
    }
};

const initStep3Error = () => {
    if ($('.page').data('action') === 'Account-SaveNewPassword') {
        atrack.trackEvent({
            event: 'tool-error',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 3,
                step_name: 'set new password',
                result: '',
                error_type: 'system',
                error_message: 'Onbekende error'
            }
        });
    }
};

const initStep4 = () => {
    const action = $('.page').data('action');
    const urlParams = new URLSearchParams(window.location.search);
    if (action === 'Account-Show' && urlParams.has('status') && urlParams.get('status') === 'passwordchanged') {
        atrack.trackEvent({
            event: 'tool-laststep',
            info: {
                type: 'form',
                name: 'password-reset',
                placement: 'login page',
                step_number: 4,
                step_name: 'confirmation',
                result: '',
                error_type: '',
                error_message: ''
            }
        });
    }
};

const init = () => {
    initAnalyticsTracker();
    initStep1();
    initStep2();
    initStep2Error();
    initStep3();
    initStep3Error();
    initStep4();
};

$(() => {
    init();
});
